import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../../components/layout"
import NewsCard from "../../components/newsCard"
import Seo from "../../components/seo"

const News = ({ location, data }) => {


  return (
    <Layout 
      scheme="dark" 
      location={location}
      pageCategory="agency"
    >
      <Seo title="MullenLowe U.S. — News" />
      <section className="container header-spacer news-grid" >
        {data.allSanityNewsItem.edges.map(newsItem => (
          <NewsCard 
            key={newsItem.node.id}  
            newsItem={newsItem} />
        ))}
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allSanityNewsItem(sort: {order: DESC, fields: datePublished}) {
      edges {
        node {
          id
          title
          source
          image {
            altText
            image {
              ...ImageWithPreview
            }
          }
          linkType
          slug {
            current
          }
          link
        }
      }
    }
  }
`

export default News
