import React from "react"
import { Link } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import Arrow from "../images/svg/arrow.svg"
import { Fade } from "react-awesome-reveal"
import PropTypes from "prop-types"

const NewsCard = ({ newsItem }) => {
  return (
    <Fade className="card" delay={100}>
      <div>
        {newsItem.node.linkType === "external" && (
          <a href={newsItem.node.link} target="_blank" rel="noreferrer">
            <CardContent newsItem={newsItem} />
          </a>
        )}

        {newsItem.node.linkType === "internal" && (
          <Link to={`/agency/news/${newsItem.node.slug?.current}`}>
            <CardContent newsItem={newsItem} />
          </Link>
        )}
      </div>
    </Fade>
  )
}

const CardContent = ({ newsItem }) => (
  <>
    {newsItem.node.image?.image.asset && (
      <>
        <div className="card__img-wrap">
          <SanityImage
            {...newsItem.node.image.image}
            alt={newsItem.node.image?.altText}
          ></SanityImage>
        </div>
      </>
    )}
    <div className="card__details">
      <div className="card__detail-source-wrap">
        <p className="card__detail-source">{newsItem.node.source}</p>
      </div>
      <div className="card__detail-title-wrap">
        <p className="card__detail-title"> {newsItem.node.title}</p>
        <div className="card__arrow-wrap">
          <Arrow />
        </div>
      </div>
    </div>
  </>
)

NewsCard.propTypes = {
  newsItem: PropTypes.object.isRequired,
}

export default NewsCard
